* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans' !important;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.placeholderText {
  color: #94a4ab
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
 
.text-editor {
  margin: 0 auto;
}

.ql-editor {
  height: 250px;
}

.ql-container {
  border-radius: 0px 0px 4px 4px;
}

.ql-toolbar{
  border-radius: 4px 4px 0px 0px;
}